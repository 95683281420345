import request from '@/utils/request'
import Qs from 'qs'
export function getGroupList (query) {
  return request({
    url: '/bh/group/find',
    method: 'get',
    params: {
      ...query
    }
  })
}

export function add (from) {
  return request({
    url: 'http://www.eliasmorgen.xyz:18888/admin/products',
    method: 'post',
    data: Qs.stringify({
      employeeRoleDTO: JSON.stringify(from)
    })
  })
}
/**
 * 分配职务和分配分组
 * @param query
 */
export function assignGroup (query) {
  return request({
    url: '/bh/group/assign',
    method: 'get',
    params: {
      ...query
    }
  })
}
