<template>
  <div class="boxRO">
    <el-row class="dash-board">
      <el-button v-if="listQuery.classId" v-permission="['Roster:download']" type="primary"  @click="doDownLoad()" class="inquire">
        <!-- <el-link :href="this.getUrl+'/class/downLoadExcel?id='+this.listQuery.classId">下载</el-link> -->
        <!-- <el-link :href="this.getUrl+'/class/downLoadExcel?id='+this.listQuery.classId">下载</el-link> -->下 载
      </el-button>
      <el-form>
          <el-form-item label-width="20px" label="" prop="classData" class="sele">
            <el-select placeholder="请选择班级" v-model="listQuery.classId"
                      @change="classChange"
                      filterable
                      clearable>
              <el-option v-for="(item,index) in classData"
                        :key="index"
                        :label="item.className"
                        :value="item.classId"/>
            </el-select>
            <span>-</span>
            <el-select placeholder="请选择分组" v-model="listQuery.groupId"
                      @change="groupChange"
                      filterable
                      clearable>
              <el-option v-for="(item,index) in groupData"
                        :key="index"
                        :label="item.value"
                        :value="item.id"/>
            </el-select>
          </el-form-item>
      </el-form>
    </el-row>
    <el-row class="bg">
      <el-table
        :data="tableData"
        style="width: 100%;">
        <el-table-column
          label="ID"
          align="center"
          width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.groupId }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="姓名"
          align="center"
          width="130">
          <template slot-scope="scope">
            <span>{{ scope.row.stuName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="性别"
          align="center"
          width="130">
          <template slot-scope="scope">
            <span  v-if="scope.row.sex==0 ">男</span>
            <span  v-if="scope.row.sex==1 ">女</span>
          </template>
        </el-table-column>
        <el-table-column
          label="单位名称"
          align="center"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.depName }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="单位职务"
          align="center"
          width="200">
          <template slot-scope="scope">
            <span>{{ scope.row.stuDuty }}</span>
          </template>
        </el-table-column>
        <el-table-column label="联系电话"
                        align="center"
                        width="140">
          <template slot-scope="scope">
            <span>{{ scope.row.stuPhone }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="班级职务"
          align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.classPosition }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top:20px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.pageNum"
        :page-size="listQuery.pageSize"
        layout="total,sizes,  prev, pager, next, jumper"
        @pagination="getList"
        :total="total"
        class="pag"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
  import { getUrl } from '@/utils/request.js'
  import { getList, FZDownLoad } from '@/api/roster'
  import { getGroupList } from '@/api/group'
  // import { getClassList } from '@/api/classApi'
  import { getAllClassByMoHu } from '@/api/user.js'
  export default {
    name: 'Roster',
    data () {
      return {
        getUrl: getUrl(),
        total: 0,
        listQuery: {
          pageNum: 1,
          pageSize: 10
        },
        tableData: [],
        classData: [], // 存储班级信息
        groupData: [], // 存放分组信息
        classId: 0,
        form: {},
        input: '',
        title: '填写请假信息',
        reapplyfor: false,
        formLabelWidth: '100px'
      }
    },
    created () {
      this.getList()
      this.findClass()
    },
    mounted () {
    },
    methods: {
      doDownLoad() {
        var Name
        this.classData.forEach(item => {
          if (item.classId === this.listQuery.classId) {
            Name = item.className
          }
        })
        const id = this.listQuery.classId
        FZDownLoad(id).then(resp => {
          // 这种方式也可以
          //  const blob = new Blob([resp.data], {
          //     type: 'application/vnd.ms-excel'
          //   })
          //   const objectUrl = URL.createObjectURL(blob)
          //   console.log(objectUrl)
          //   window.location.href = objectUrl
          const link = document.createElement('a')
          const blob = new Blob([resp.data], { type: 'application/vnd.ms-excel' })
           link.style.display = 'none'
           // 设置连接
           link.href = URL.createObjectURL(blob)
           link.download = Name + '学员花名册'
           document.body.appendChild(link)
           // 模拟点击事件
           link.click()
        }).catch((err) => {
          console.log(err)
        })
      },
      handleSizeChange(e) {
        this.listQuery.pageSize = e
        this.getList()
      },
      handleCurrentChange(e) {
        this.listQuery.pageNum = e
        this.getList()
      },
      findClass() {
        // getClassList().then(response => {
        //   this.classData = response.data.data
        // })
         getAllClassByMoHu().then(response => {
          this.classData = response.data.data.rows
        })
      },
      getList() {
        getList(this.listQuery)
          .then(response => {
            this.tableData = response.data.data.rows
            this.total = response.data.data.total
          })
          .catch(() => {
            this.tableData = []
            this.total = 0
          })
      },
      classChange() {
        getGroupList({ pageNum: 1, pageSize: 100, classId: this.listQuery.classId, type: 0 }).then(res => {
          this.groupData = res.data.data.rows
        })
        if (this.listQuery.classId === null || this.listQuery.classId === '') {
          this.listQuery.groupId = null
        }
        this.getList()
      },
      groupChange() {
        this.getList()
      }
    }
  }
</script>

<style scoped>
  .boxRO{ margin-top: -10px; }
.dash-board {display: flex; position: relative; background-color: white; height: 60px; padding: 10px 0;}
.bg{margin-top: 15px; padding: 20px; background-color: white;}
  .inquire{
    position: absolute;
    top: 15px;
    left: 85%;
  }
  .reset{
    position: absolute;
    top: 15px;
    left: 90%;
  }
  .sele{
    position: absolute;
    top: 15px;
    left: 0px;
  }
.el-link.el-link--default {
    color: #fff;
}
</style>
