import request from '@/utils/request'
import Qs from 'qs'
export function getList (query) {
  return request({
    url: '/bh/group/findStuGroupRoster',
    method: 'get',
    params: {
        ...query
    }
  })
}

export function add (from) {
  return request({
    url: '/admin/products',
    method: 'post',
    data: Qs.stringify({
      employeeRoleDTO: JSON.stringify(from)
    })
  })
}

/**
 *导出excel表格i
 */
export function downLoadExcel (id) {
  return request({
    url: `/class/downLoadExcel?&id=${id}`,
    method: 'get'
  })
}
/**
 *下载分组花名册
 */
 export function FZDownLoad (id) {
  return request({
    url: `/class/downLoadExcel?id=${id}`,
    method: 'get',
    responseType: 'blob'
  })
}
